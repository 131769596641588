@import 'fonts';

.align-center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.initial-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.full-height {
    height: calc(100vh - 112px);
}

.welcome-paragraph {
    padding-left: 16px;
}

.welcome-text {
    margin-bottom: 10px;
}

.upcoming-courses-table {
    tr:nth-of-type(even) {
        background-color: rgb(0 0 0 / 4%);
    }

    table {
        min-width: 650px;
    }
}

.no-wrap {
    white-space: nowrap;
}

.with-ellipsis-course {
    display: block;
    overflow: hidden;
    max-width: 60ch;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.upcoming-courses-table .cell {
    font-size: 1.2rem;
}

.with-ellipsis-teachers {
    display: block;
    overflow: hidden;
    max-width: 45ch;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.footer {
    text-align: center;
}

.component-stack {
    text-align: left;
}

.website-link {
    text-decoration: none;
    color: inherit;
}
