/* latin-ext */
@font-face {
    font-family: Overpass;
    font-style: italic;
    font-weight: 100;
    src: local('Overpass Thin Italic'), local('Overpass-ThinItalic'),
        url('./fonts/thg-CA5nD5lyYWLwXbqXXj0LW-43aMEzIO6XUTLjad8.woff2') format('woff2');
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: Overpass;
    font-style: italic;
    font-weight: 100;
    src: local('Overpass Thin Italic'), local('Overpass-ThinItalic'),
        url('./fonts/thg-CA5nD5lyYWLwXbqXXugdm0LZdjqr5-oayXSOefg.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin-ext */
@font-face {
    font-family: Overpass;
    font-style: italic;
    font-weight: 300;
    src: local('Overpass Light Italic'), local('Overpass-LightItalic'),
        url('./fonts/R77XtXNe7WC4SXZBLWmy80_0lycXMw8PhobHtu2Qgco.woff2') format('woff2');
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: Overpass;
    font-style: italic;
    font-weight: 300;
    src: local('Overpass Light Italic'), local('Overpass-LightItalic'),
        url('./fonts/R77XtXNe7WC4SXZBLWmy88u2Q0OS-KeTAWjgkS85mDg.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin-ext */
@font-face {
    font-family: Overpass;
    font-style: italic;
    font-weight: 400;
    src: local('Overpass Italic'), local('Overpass-Italic'),
        url('./fonts/zWeSFOtQujTgbIw6reSB5iEAvth_LlrfE80CYdSH47w.woff2') format('woff2');
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: Overpass;
    font-style: italic;
    font-weight: 400;
    src: local('Overpass Italic'), local('Overpass-Italic'),
        url('./fonts/nFeT4yZLKoYlkcTXEa9P0vk_vArhqVIZ0nv9q090hN8.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin-ext */
@font-face {
    font-family: Overpass;
    font-style: italic;
    font-weight: 700;
    src: local('Overpass Bold Italic'), local('Overpass-BoldItalic'),
        url('./fonts/E5UsN4VY1e_Twk_bY6TpQBHJTnCUrjaAm2S9z52xC3Y.woff2') format('woff2');
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: Overpass;
    font-style: italic;
    font-weight: 700;
    src: local('Overpass Bold Italic'), local('Overpass-BoldItalic'),
        url('./fonts/E5UsN4VY1e_Twk_bY6TpQIWiMMZ7xLd792ULpGE4W_Y.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin-ext */
@font-face {
    font-family: Overpass;
    font-style: normal;
    font-weight: 100;
    src: local('Overpass Thin'), local('Overpass-Thin'),
        url('./fonts/8EAqMwChj-DdMr6KoFIHhiEAvth_LlrfE80CYdSH47w.woff2') format('woff2');
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: Overpass;
    font-style: normal;
    font-weight: 100;
    src: local('Overpass Thin'), local('Overpass-Thin'),
        url('./fonts/EgQDlWK0VLXmbzxmlJaMWPk_vArhqVIZ0nv9q090hN8.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin-ext */
@font-face {
    font-family: Overpass;
    font-style: normal;
    font-weight: 300;
    src: local('Overpass Light'), local('Overpass-Light'),
        url('./fonts/tgqY5qOfB6w1HP32JTSrLCYE0-AqJ3nfInTTiDXDjU4.woff2') format('woff2');
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: Overpass;
    font-style: normal;
    font-weight: 300;
    src: local('Overpass Light'), local('Overpass-Light'),
        url('./fonts/tgqY5qOfB6w1HP32JTSrLI4P5ICox8Kq3LLUNMylGO4.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin-ext */
@font-face {
    font-family: Overpass;
    font-style: normal;
    font-weight: 400;
    src: local('Overpass Regular'), local('Overpass-Regular'),
        url('./fonts/xf9_Q4u6c0bcCd8geHaSgxTbgVql8nDJpwnrE27mub0.woff2') format('woff2');
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: Overpass;
    font-style: normal;
    font-weight: 400;
    src: local('Overpass Regular'), local('Overpass-Regular'),
        url('./fonts/o3S9a2vetxRRO8sKA6PL0_esZW2xOQ-xsNqO47m55DA.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin-ext */
@font-face {
    font-family: Overpass;
    font-style: normal;
    font-weight: 600;
    src: local('Overpass SemiBold'), local('Overpass-SemiBold'),
        url('./fonts/w4FT_68VVErTcMDJuNJOOSYE0-AqJ3nfInTTiDXDjU4.woff2') format('woff2');
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: Overpass;
    font-style: normal;
    font-weight: 600;
    src: local('Overpass SemiBold'), local('Overpass-SemiBold'),
        url('./fonts/w4FT_68VVErTcMDJuNJOOY4P5ICox8Kq3LLUNMylGO4.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin-ext */
@font-face {
    font-family: Overpass;
    font-style: normal;
    font-weight: 700;
    src: local('Overpass Bold'), local('Overpass-Bold'),
        url('./fonts/z_QV2-z5a6o4brQE8JEVyiYE0-AqJ3nfInTTiDXDjU4.woff2') format('woff2');
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: Overpass;
    font-style: normal;
    font-weight: 700;
    src: local('Overpass Bold'), local('Overpass-Bold'),
        url('./fonts/z_QV2-z5a6o4brQE8JEVyo4P5ICox8Kq3LLUNMylGO4.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin-ext */
@font-face {
    font-family: Overpass;
    font-style: normal;
    font-weight: 900;
    src: local('Overpass Black'), local('Overpass-Black'),
        url('./fonts/Ckmwq2XGMtGYgrTOyh6tCiYE0-AqJ3nfInTTiDXDjU4.woff2') format('woff2');
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: Overpass;
    font-style: normal;
    font-weight: 900;
    src: local('Overpass Black'), local('Overpass-Black'),
        url('./fonts/Ckmwq2XGMtGYgrTOyh6tCo4P5ICox8Kq3LLUNMylGO4.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
